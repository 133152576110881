// Variable overrides
$body-bg: #f5f5f5;
$link-color: #0052cc;
$layout-transition-speed: 0.2s;
$enable-elevations:false;

// Card Variables
$card-border-color: transparent;
$card-bg: transparent;
$card-body-bg: transparent;
$card-icon-bg:red;
$card-header-color:red;

//sidebar Variables
$sidebar-dark-bg: #263238;
$sidebar-dark-color: white;

$sidebar-dark-nav-link-active-icon-color: white;
$sidebar-dark-nav-link-active-bg: #455A64;

//header Variables
$theme-colors: (
  "dark": #1e282d,
  "primary": #2caad7
);

//form variables
$input-border-color: #9E9E9E;
$input-color: #212121;
/*$input-group-addon-border-color:#9E9E9E;*/
