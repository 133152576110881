/*Header*/
div.haf-page-header {
    background-color: transparent;
    font-weight: bold;
    font-size: 1.5rem;
    border-bottom: none;
}

.haf-adultinfo-header {
    /* background-color: #3c4b64;
    color:#f5f5f5; */
    top: calc(1.5rem + 95px);
    font-weight: bold;
    /* margin-top: -30px;
    margin-bottom: 5px; */
}

/*Toggle buttons such as the filter in My Assessments*/
label.btn.haf-toggleBtn{
      margin-left:5px;
      margin-top:1px;
      color: white;
      height:calc(1.5em + 0.75rem);
      border:none;
      font-weight:400;
      border-radius: 700px;
}

.haf-toggleBtn [type=checkbox]{
    margin-right:6px;
}

/*Status color toggle button codes*/

/*iframe*/
.iframe-placeholder
{
   background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="gray" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">Loading...</text></svg>') 0px 0px no-repeat;
}


.card-iframe-gettingstarted {
  display: block;
  z-index:1;
  height: 100%;
  min-height: 100%;
}

/* CHANGES */


.form-control {
    border: 1px solid #9E9E9E;
}
.checkbox-form-control {
  transform: scale(2.0);
}
/* DROPDOWN menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #23282c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c8ced3 !important;
  border-radius: 0.25rem;
}
/* Navs */

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #c8ced3;
}
.nav-tabs {
  cursor: pointer;
}
.nav-tabs .nav-link.active {
    color: #2f353a;
    background: #fff;
    border-color: #c8ced3;
    border-bottom-color: #fff;
    cursor: pointer;
}

.tab-content .tab-pane {
    padding: 1rem;
    overflow-x: hidden;
}
.tab-content > .active {
    display: block;
}

/********************************************************************************************/


/*Badges*/
.haf-cell-badge {
    /*padding: 3px 10px 3px 10px;
    font-size: .765625rem;
    border-radius: 700px;*/
    font-weight: 600;
    min-width: 100px;
    cursor: default;
}

.haf-tgl-badge {
    font-weight: 600;
}
div.haf-tgl-badge {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    background-color: transparent;
}


.haf-badge-status-new{
    color: #3FB547;
}

.haf-badge-status-not-completed{
    color: #EF5350;
}

.haf-badge-status-completed {
    color: #3399ff;
}

/*Grid styles*/
form.haf-gridToolbar.form-inline{
    margin-bottom:10px;
}

.haf-grid th{
    background-color:#f5f5f5;;
}

.haf-grid tbody{
    background-color:white;
}

.haf-grid tbody td{
    vertical-align: middle;
}

/*Grid styles*/
.haf-gridTopCounter{
    margin:auto;
    margin-left: 20px;
    font-size:1rem;
}

/*Icon and notification styling*/
span.haf-primary-notif-text  {
    color:#263238;
    cursor: help;
}

span.haf-secondary-notif-text  {
    color:#607D8B;
    cursor: help;
}

/*alert styles*/
div.haf-action-alert{
  margin-bottom: 0px;
  margin-left: 10px;
  height: 100%;
  max-height: 100%;
  line-height:.875;
  border:none;
}

div.haf-info-data{
  margin-bottom: 0px;
  margin-left: 10px;
  height: 100%;
  max-height: 100%;
  line-height:.875;
  border:none;
}

/*form section nav*/
.haf-form-nav.list-group{
    max-height: calc(100vh - 210px);
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    border-color: #c8ced3 !important;

}

/*dropdown menu styles*/
.haf-dropdown-menu .dropdown-divider{
  margin:0;
}

.haf-dropdown-menu button.dropdown-item{
  border-bottom:none;
  color:#263238;
}
.haf-dropdown-menu button.dropdown-item i{
  color:#455A64;
}

.haf-dropdown-menu button.dropdown-item.disabled{
  color:#B0BEC5;
}
.haf-dropdown-menu button.dropdown-item.disabled i{
  color:#B0BEC5;
}

div.haf-nav-title {
  background-color: /*#e7ecef*/ white;
  padding: .75rem 1.25rem;
  border-bottom: 1px solid #cad4dc;
  font-weight:600;
}

div.haf-form-toolbar {
  padding:0.75rem 1.25rem;
}

ul.haf-form-nav i{
    min-width:10px;
}

ul.haf-form-nav a{
    font-weight:500;
    color:black;
}
ul.haf-form-nav a.active,
ul.haf-form-nav a.active:hover
{
    color:white !important;
}

ul.haf-form-nav a:hover{
    border-top-color:transparent;
    border-bottom-color:transparent;
    color:black !important;

}


/*Form sections*/
input[type=date] {
    max-width:150px;
}
input[type=number]{
    max-width:100px;
}
input[type=text],
input[type=select],
select{
    max-width:600px;
}

input[type=number].moneyField {
    max-width:150px;
}


.haf-card-field div.card-header{
  font-weight: 600;
  font-size: 16px;
}

.haf-form-questionID {
  font-weight:200;
  white-space: nowrap;
  padding-left: 0.75rem;
}

.haf-subtopic-intcard {
    /* width: calc(50% - 1.5rem);
    margin-left: auto; */
    width: calc(50% - 0.3rem);
    margin-left: auto;
}

.haf-subcard-title {
    font-weight:600;
    font-size: 1rem;
}

.haf-subcard-content {
  font-weight:600;
  font-size: 14px;
}


div.haf-form-rankfield.input-group {
    max-width: 100px;
    margin-left: 20px;
}

.haf-form-mediumfield  {
    max-width:600px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}



.haf-aform-field.haf-warning-field input,
.haf-aform-field.haf-warning-field select,
.haf-aform-field.haf-warning-field textarea{
    border-color:#f86c6b;
}

.haf-aform-field.haf-warning-field .form-check label{
    text-decoration: underline;
    text-decoration-color:#f86c6b;
}


/*MENU STYLES*/
.sidebar .nav-link{
    letter-spacing: 0.025em;
}
.sidebar .nav-link.active{
    font-weight:600;
    /*background-color:#2e3145 !important;*/
}
/*********************************/
/*
.haf-navbtn-new {
  font-weight: 800;
  background-color:#4dbd74;
}
*/

a.c-sidebar-nav-link.haf-navbtn-new {
  background: #4dbd74;
}
a.c-sidebar-nav-link.haf-navbtn-new {
  border-radius: .5rem;
}

.c-sidebar li.c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new{
  margin-top: .5rem ;
  margin-bottom: .5rem ;
}

.c-sidebar .ps li.c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new {
  margin-top: .5rem ;
  margin-bottom: .5rem ;
  margin-left: .5rem ;
  margin-right: .5rem ;
}

.c-sidebar .ps .c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new {
  border-radius: .25rem;
}

.c-sidebar .c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new {
  font-weight: 600;
  background-color:#4dbd74;
  transition: color .15s esase-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
}

.c-sidebar .c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new:hover{
  background-color:#3a9d5d;
}
.c-sidebar .c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new .nav-icon {
  color:white !important;
}
.c-sidebar .c-sidebar-nav-item a.c-sidebar-nav-link.haf-navbtn-new.disabled {
  background-color:rgb(77,189,116, .5);
}
.c-sidebar .c-sidebar-nav-item .disabled .nav-icon {
  color: rgb(255,255,255,.5) !important;
}
/***********************/
/*
a.c-sidebar-nav-link.haf-navbtn-new a.disabled {
  background-color:rgb(77,189,116, .5);
}


.c-sidebar li.c-sidebar-nav-item.haf-navbtn-new {
    margin-top: .5rem ;
    margin-bottom: .5rem ;
}

.c-sidebar .ps li.c-sidebar-nav-item.haf-navbtn-new {
    margin-top: .5rem ;
    margin-bottom: .5rem ;
    margin-left: .5rem ;
    margin-right: .5rem ;
}

.c-sidebar .ps .c-sidebar-nav-item.haf-navbtn-new a{
    border-radius: .25rem;

}

.c-sidebar .c-sidebar-nav-item.haf-navbtn-new a {
    font-weight: 600;
    background-color:#4dbd74;
    transition: color .15s esase-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition-property: color, background-color, border-color, box-shadow;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s, 0s;
}
.c-sidebar .c-sidebar-nav-item.haf-navbtn-new a:hover{
    background-color:#3a9d5d;
}

.c-sidebar .c-sidebar-nav-item.haf-navbtn-new .nav-icon {
    color:white !important;
}

.c-sidebar .c-sidebar-nav-item.haf-navbtn-new a.disabled {
    background-color:rgb(77,189,116, .5);
}
.c-sidebar .c-sidebar-nav-item.haf-navbtn-new .disabled .nav-icon {
    color: rgb(255,255,255,.5) !important;
}
*/
/******************************/

header.app-header.navbar{
    border-bottom-color:#1e282d;
}

/*Preview Modal*/
div.modal-dialog.haf-preview-modal {
    overflow:hidden;
    min-height: calc(100vh - 200px);
}

div.modal-dialog.haf-progress-modal {
  overflow:hidden;
  min-height: calc(100vh - 400px);
  max-width: 400px;
  margin-top: 200px;
}

div.haf-preview-viewer {
    min-height: calc(100vh - 330px);
    max-height: calc(100vh - 330px);
    /* height: 100vh; */
    overflow:auto;
    /* user-select: none; */
}

.haf-preview-viewer h1{
    font-size: 2rem;
    font-weight: 600;
    text-decoration: underline;
}

.haf-preview-viewer h2{
    font-size: 1.2rem;
    font-weight: 700;
}
/******************************/
.haf-newuser-modal {
  overflow:hidden;
  min-height: calc(100vh - 200px);
  width: 500px;
}
.haf-choose-group-to-share {
  overflow:hidden;
  min-height: calc(100vh - 400px);
  width: 500px;
}

/******************************/
.haf-newsubscription-modal {
  overflow:hidden;
  min-height: calc(100vh - 100px);
  min-width: 700px;
}


/*HEADER*/
div.haf-header-logo{
    width: 300px;
}
.haf-header-logo a.navbar-brand{
    align-items:center;
    justify-content: flex-start;
}

.app-header div.haf-header-logo {
    text-align:left !important;
    margin-left: .5rem;
}


.modal-changeassessment{
    overflow:hidden;
    min-height: calc(100vh - 200px);
    min-width: 1000px;
}


.modal-changeassessment-text{
    font-size: 80%;
    position:left;
    left:-12px;
}


@media (min-width: 992px) {
    .brand-minimized .app-header .navbar-brand .navbar-brand-full{
        display:inline-flex !important;
    }

}

/*card boxes*/
.haf-selectType-deck div.card {
    background-color:white;
}
.haf-selectType-deck div.card-title{
    font-size:18px;
    font-weight:700;
    line-height:17px;
}

.haf-selectType-deck div.card-subtitle{
    font-size:14px;
}

.haf-selectType-deck div.c-callout{
    font-size:90%
}

.title-global {
  display:flex;
  /* white-space: nowrap; */
}



.card-iframe-gettingstarted {
  display: flex;
  z-index:1;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

/* CHANGES */

.app-body .main {
  flex: 1 1;
  min-width: 0;
}




.form-control {
    border: 1px solid #9E9E9E;
}
/* DROPDOWN menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #23282c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c8ced3 !important;
  border-radius: 0.25rem;
}

.c-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 56px;
  min-width: 100%;
  z-index: 1020;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 50px;
}

.c-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1020;
  height: 50px;
}

/* Navs */

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #c8ced3;
}
.nav-tabs {
  cursor: pointer;
}
.nav-tabs .nav-link.active {
    color: #2f353a;
    background: #fff;
    border-color: #c8ced3;
    border-bottom-color: #fff;
    cursor: pointer;
}

.tab-content .tab-pane {
    padding: 1rem;
}
.tab-content > .active {
    display: block;
}

/********************************************************************************************/
/* .rectangle-total {
  display:inline-block;
  height: 45px;
  min-width: 110px;
  background:gray;
  color:white;
  border-radius: 5%;
  text-align: center;

}
.rectangle-new {
  display:inline-block;
  min-width: 110px;
  height: 45px;
  background:#3FB547;
  color:white;
  border-radius: 5%;
  text-align: center;
}
.rectangle-not-completed {
  display:inline-block;
  height: 45px;
  min-width: 110px;
  background:#EF5350;
  color:white;
  border-radius: 5%;
  text-align: center;
}
.rectangle-completed {
  display:inline-block;
  height: 45px;
  min-width: 110px;
  background:#39f;
  color:white;
  border-radius: 5%;
  text-align: center;
} */
.refresh-button-pos {
  /* display:inline-block; */
  float: right;
  margin-right: 0px;
  margin-top: 2px;
  /* display: flex;
  flex-flow: row wrap; */
  /* margin-block-end: 100px; */
}
.refresh-button {
  display:inline-block;
  height: 67px;
  min-width: 110px;
  /* background:#ffcc00;
  color:white; */
  /* font-size: large; */
  font-weight: 500;
  border-radius: 5%;
  text-align: center;
  /* -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out; */
  /* transition: opacity 0.3s ease-in-out; */
}

.rectangle-total {
  display:inline-block;
  margin-top: 2px;
  height: 67px;
  min-width: 110px;
  background:gray;
  color:white;
  /* font-size: large; */
  font-weight: 500;
  border-radius: 5%;
  text-align: center;
  /* -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out; */
  transition: opacity 0.3s ease-in-out;
}
.rectangle-new {
  display:inline-block;
  margin-top: 2px;
  min-width: 110px;
  height: 67px;
  background:#3FB547;
  color:white;
  /* font-size: large; */
  font-weight: 500;
  border-radius: 5%;
  text-align: center;
  /* -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out; */
  transition: opacity 0.3s ease-in-out;
}

.rectangle-not-completed {
  display:inline-block;
  margin-top: 2px;
  height: 67px;
  min-width: 110px;
  background:#EF5350;
  color:white;
  /* font-size: large; */
  font-weight: 500;
  border-radius: 5%;
  text-align: center;
  /* -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out; */
  transition: opacity 0.3s ease-in-out;
}
.rectangle-completed {
  display:inline-block;
  margin-top: 2px;
  height: 67px;
  min-width: 110px;
  background:#39f;
  color:white;
  /* font-size: large; */
  font-weight: 500;
  border-radius: 5%;
  text-align: center;
  /* -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out; */
  transition: opacity 0.3s ease-in-out;
}

.rectangle-uncheck {
  opacity: 0.3;
}

.c-sidebar {
  position: relative;
  display: flex;
  flex: 0 0 200px;
  flex-direction: column;
  order: -1;
  width: 200px;
  padding: 0;
  box-shadow: none;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 200px;
}

@media (max-width: 992px) /*and (max-width: 992px)*/
{
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 56px;
}
}
/* style={{maxHeight:"36px", marginBottom:"5px", color:"white",borderRadius:"20px", fontWeight:"500"}} */
.credit-buttom {
  max-height: 30px;
  margin-bottom: 5px;
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
  /* padding-bottom: 1.5em; */
  padding-top: 0.1em;
  border: 0px;
}

.credit-buttom:active, .credit-buttom:focus {
  outline: 0px !important;
  border: 0px !important;
}
button.credit-buttom::-moz-focus-inner {
  border: 0px !important;
  outline: 0px !important;
}

.credit-buttom-form {
  /* max-height: 20px; */
  margin-top: 13px;
  /* border-radius: 20px; */
  font-size: small;
  font-weight: 500;
  /* padding-bottom: 1.5em; */
  /* padding-top: 0.1em; */
  border: 0px;
}

.credit-buttom-form:active, .credit-buttom-form:focus {
  outline: 0px !important;
  border: 0px !important;
}
button.credit-buttom-form::-moz-focus-inner {
  border: 0px !important;
  outline: 0px !important;
}



.haf-cashflow-form tbody td {
  text-align: center;
  align-items: center;
}

.haf-cashflow-form th {
  text-align: center;
  align-items: center;
}

.haf-cashflow-form td input[type="checkbox"] {
  float: left;
  margin: 0 auto;
  width: 100%;
}

/*Callouts*/
.hh-stats-callouts div{
    border: 1px solid lightgray;

}

.hh-stats-callouts .hh-callout-title{

}

.hh-stats-callouts .hh-callout-value{

}


.cashflow-sort-field-select {
  display: block;
  font-size: 11px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em; width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
   box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
   -moz-appearance: none;
   -webkit-appearance: none;
   appearance: none;
   background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  }
   .cashflow-sort-field-select::-ms-expand { display: none; }
   .cashflow-sort-field-select:hover { border-color: #888; }
   .cashflow-sort-field-select:focus { border-color: #aaa;
   box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
   box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
   outline: none;
  }
   .cashflow-sort-field-select option { font-weight:normal; }
   *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
  background-position: left .7em top 50%, 0 0;
  padding: .6em .8em .5em 1.4em;
  }

/*========================================================================================*/
/*                                                                                      *
 *                               For narrative and preview                              *
 *                                                                                      */
/*========================================================================================*/

.subtitle {
  font-family: Times;
  font-weight: bold;
  font-size : 18pt;
}
.maintitle {
  font-family: Times;
  font-weight: bold;
  font-size : 24pt;
}

/*========================================================================================*/
/*                                                                                      *
 *                                   FOR CARD PAYMENT                                   *
 *                                                                                      */
/*========================================================================================*/
.card-payment {
  min-width: 320px;
  margin-left: 10px;
  height: 400px;
  text-align: left;
}

/*========================================================================================*/
/*                                                                                      *
 *                                   CREDIT CHECKBOX                                   *
 *                                                                                      */
/*========================================================================================*/

.haf-badge-receipts{
  color: #3FB547;
}

.haf-badge-expense{
  color: #EF5350;
}

/*========================================================================================*/
/*                                                                                      *
 *                                     PERFORM TABLE                                    *
 *                                                                                      */
/*========================================================================================*/
.parent-perform-table {
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}
.haf-perform-table tbody {
  display:block;
  overflow-y:scroll;
  height:211px;
  width:100%;
  text-align:center;
}
.haf-perform-table thead tr {
  display:block;
  text-align:center;
}

/***************************************************************************************/
/*                                                                                      *
 *                                         RULES                                        *
 *                                                                                      */
/***************************************************************************************/

.container-rule-card {
  /* border: 2px solid grey; */
  min-height: 200px;
  display: flex;

  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: center; */
}

.container-rule-card #SectionId {
  height:120px
}


.predicate-rule-test {
  border: 2px solid grey;
  overflow-y: scroll;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding-left: 10px;
  padding-top: 10px;

  /* align-items: center;
  justify-content: center; */
}

.assessment-rule-test {
  border: 2px solid grey;
  overflow-y: scroll;
  max-height: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding-left: 10px;
  padding-top: 10px;
  /* align-items: center; */
  /* justify-content: center; */
}
.result-rule-test {
  border: 2px solid grey;
  overflow-y: scroll;
  max-height: 235px;
  min-height: 235px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding-left: 10px;
  padding-top: 10px;
  /* align-items: center;
  justify-content: center; */
}

.oper-rule-card {
  /* border: 2px solid grey; */
  min-height: 50px;
  /* min-width: 250px; */
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  /* align-content: center; */
  justify-content: space-around;
  flex-wrap: wrap;
}

.input-rule-statement {
  /* style={{ maxWidth: "100px", marginLeft: "10px" }} */
  max-width: 200px;
  margin-left: 10px;
  margin-top: -5px;
}

.input-rule-result {
  margin-top: 10px;
  height: 80px;
  width: 400px;
}

div.haf-newrule-modal {
  overflow:auto;
  min-width: calc(100vw - 100px);


}

.haf-newrule-modal .modal-body{
  min-height: calc(100vh - 200px);
  min-width: 1080px;

}

.row.haf-rule-row {

}

div.haf-quill-column  {
  max-width:450px;
  min-height:calc(100vh - 253px);
}

div.rule-result-editor {
  height:calc(100% - 130px);
}


.parent-rule-table {
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}
.haf-rule-table tbody {
  display:block;
  overflow:scroll;
  height:211px;
  width:100%;
  text-align:center;
}
.haf-rule-table thead tr {
  display:block;
  text-align:center;
  /* height : 20px; */
}
.rule-field-text {
  display: flex;
  min-width: 100%;
}

.wide-assessment-button {
  display: flex;
  min-width: 200px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.rule-placeholder {
  width: 150px;
  margin-left: 10px;
}
.rule-name {
  width: 220px;
  margin-left: 20px;
}
.problem-list {
  width: 490px;
  margin-left: 10px;
}

/***************************************************************************************/
/*                                                                                      *
 *                                         TRANSFORMRULES                                        *
 *                                                                                      */
/***************************************************************************************/

.container-transformrule-card {
  /* border: 2px solid grey; */
  min-height: 200px;
  display: flex;

  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: center; */
}

.container-transformrule-card #SectionId {
  height:120px
}


.predicate-transformrule-test {
  border: 2px solid grey;
  overflow-y: scroll;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding-left: 10px;
  padding-top: 10px;

  /* align-items: center;
  justify-content: center; */
}

.assessment-transformrule-test {
  border: 2px solid grey;
  overflow-y: scroll;
  max-height: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding-left: 10px;
  padding-top: 10px;
  /* align-items: center; */
  /* justify-content: center; */
}
.result-transformrule-test {
  border: 2px solid grey;
  overflow-y: scroll;
  max-height: 235px;
  min-height: 235px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  padding-left: 10px;
  padding-top: 10px;
  /* align-items: center;
  justify-content: center; */
}

.oper-transformrule-card {
  /* border: 2px solid grey; */
  min-height: 50px;
  /* min-width: 250px; */
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  /* align-content: center; */
  justify-content: space-around;
  flex-wrap: wrap;
}

.input-transformrule-statement {
  /* style={{ maxWidth: "100px", marginLeft: "10px" }} */
  max-width: 200px;
  margin-left: 10px;
  margin-top: -5px;
}

.input-transformrule-result {
  margin-top: 10px;
  height: 80px;
  width: 400px;
}

div.haf-newtransformrule-modal {
  overflow:auto;
  min-width: calc(100vw - 100px);


}

.haf-newtransformrule-modal .modal-body{
  min-height: calc(100vh - 200px);
  min-width: 1080px;

}

.row.haf-transformrule-row {

}

div.haf-quill-column  {
  max-width:450px;
  min-height:calc(100vh - 253px);
}

div.haf-transformrule-right  {
  max-width:600px;
  min-height:calc(100vh - 253px);
}

div.rule-result-editor {
  height:calc(100% - 130px);
}


.parent-transformrule-table {
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
   margin-left: 15px;
}
.haf-transformrule-table tbody {
  display:block;
  overflow:scroll;
  height:211px;
  width:100%;
  text-align:center;
}
.haf-transformrule-table thead tr {
  display:block;
  text-align:center;
  /* height : 20px; */
}
.transformrule-field-text {
  display: flex;
  min-width: 100%;
}

.transformrule-placeholder {
  width: 150px;
  margin-left: 10px;
}
.transformrule-name {
  width: 220px;
  margin-left: 20px;
}

/***************************************************************************************/
/*                                                                                      *
 *                                         IMPRESSION                                   *
 *                                                                                      */
/***************************************************************************************/
.haf-impression-modal {
  overflow:auto;
  min-height: calc(100vh - 100px);
  /* min-width: calc(100vw - 450px); */
  min-width: 550px;
}
.haf-impression-modal {
  overflow:auto;
  min-height: calc(100vh - 400px);
  /* min-width: calc(100vw - 450px); */
  min-width: 550px;
}
.impression-result-editor {
  height: 170px;
}
.container-impression-card {
  /* border: 2px solid grey; */
  /* width: 500px; */
  min-height: calc(100vh - 300px);
  display: flex;

  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: center; */
}
.impression-reference-field {
  height:33px;
}
/***************************************************************************************/
/*                                                                                      *
 *                                         PROMOS                                        *
 *                                                                                      */
/***************************************************************************************/
.container-promo-card {
  /* border: 2px solid grey; */
  min-height: 200px;
  display: flex;

  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: center; */
}
.haf-newpromo-modal {
  overflow:auto;
  min-height: calc(100vh - 300px);
  /* min-width: calc(100vw - 450px); */
  min-width: 550px;
}

.promo-field-text {
  width: 320px;
}

.promo-date {
  width: 200px;
}
/************************************************/
 .my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}




.note {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.note-exit {
  animation: note 800ms ease-out forwards;
}

.note-enter {
  animation: note 800ms ease-in alternate-reverse;
}

@keyframes note {
  0% {
    transform: translateX(0);
  }

  30% {
    opacity: 0.7;
    transform: translateX(30px);
  }

  100% {
    opacity: 0;
    transform: translateX(-200px);
  }
}

.rule-select-assessment {
  /* width:100px; */
  overflow-x: scroll;
  height: calc(100% - 380px) !important;
}

.password-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}


div.modal-dialog.haf-history-modal {
  overflow:hidden;
  /* max-height: calc(100vh - 230px); */
  min-height: 400px;
  max-width:900px;
}

/* div.haf-history-modal {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  height: 100vh;
  overflow:auto;
  user-select: none;
} */

.haf-history-modal-table tbody {
  display:block;
  overflow:scroll;
  height: 300px;
  width:100%;
  /* text-align:center; */
  margin-bottom: -15px;
}
.haf-history-modal-bottom {

}

.haf-history-modal-table thead tr {
  display:block;
  text-align:center;
}

.modal-titletour {
  box-shadow: 10px 5px 5px black;
}
.react-joyride__tooltip {
  box-shadow: 10px 5px 5px black;
}
/* .react-joyride__tooltip button:focus { outline: none; } */

.react-joyride__spotlight
{
  /* border: 3px solid red; */

    /* border-radius: 4px;
    background-color: silver;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.2s ease 0s; */
}

.haf-form-radio {
  /* font-family: Times; */
  text-decoration: underline;
  font-weight: bold;
  font-size : 13pt;
}
.adult-type-menu {
  /* margin-top: -20px; */
  margin: -30px 0px 0px 0px;
  /* width: 300px; */
}

.adult-type-input-global {
  width: 210px;
}
.adult-type-input-company {
  width: 172px;
}
.adult-type-input-user {
  width: 176px;
}
.adult-type-input-shared {
  width: 205px;
}

.inputFindPromoPerf {
  margin-left: 5px;
  width: 100px;
  /* border-width: 0px; */
}

.back-promo {
  margin: 2px 0px 0px 0px;
  /* border: 1px solid #9E9E9E; */
  height: 67px;
  width: 110px;
  text-align: center;
  background-color: #ffcc00;
  float: right;
  margin-right: 0px;
  border-radius: 5%;
  /* max-width: 110px; */
  /* background-color:burlywood; */
}

.backgreound-clr {
  background: #f5f5f5;
}
.top-menu {
  /* margin-top: 58px; */
  min-width: 752px;
  max-width: 752px;
  /* margin: 67px 30px auto auto; */
   /* #f5f5f5 */
}
.top-menu-remote {
  /* margin-top: 58px; */
  min-width: 752px;
  max-width: 752px;
  /* min-width: 677px;
  max-width: 677px; */
  /* margin: 10px 160px auto auto;
   border: solid #ceced0; */
}


.internalCardsBody {
  /* margin-right: -80px;
  margin-left: -20px; */
  margin-right: -100px;
  margin-left: -40px;

  /* padding-right: -100px; */
}

.internalCards {
  margin-right: auto;
  margin-left: auto;
  /* padding-right: -100px; */
}

.internalCardsFullBody {
  margin-right: -90px;
  margin-left: -20px;
  /* padding-right: -100px; */
}
/*RIGHT PANEL*/
.haf-form-right {
  display:block;
  overflow-y:auto;
  overflow-x: hidden;
  max-height : calc(100vh - 209px);
  width:100%;
  /* text-align:center; */
}

.fullscreen-button {
  border: none;
  outline: none;
  background: none;
  }
  .fullscreen-button:active {
    outline: none;
    border: none;
    }
  .fullscreen-button:focus {
    outline:none;
    box-shadow: none;
}

.horizont_scoll {
  overflow:auto;
}

.top-searchstring {
  /* padding-bottom: 10px; */
  margin-left: 15px;
}

.headline {
    border: '1px solid #000';
    height: 100;
    overflow: 'hidden';
    padding: '8px';
    width: 300;
}
.preview-asi {
  /* height: 300px; */
  zoom: 80%;
  /* width: 1000px; */
  /* overflow: scroll; */

}
.prevent-select {
  height: 500px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* pointer-events: none; */
  overflow:scroll;
}
.type-assessment-img {
  height: 35px;
  width: fit-content;
  margin-top: 15px ;
  margin-left: 20px;
  margin-bottom: -10px;
}

/*========================================================================================*/
/*                                                                                      *
 *                                     SHOW COMPANT TABLE                                    *
 *                                                                                      */
/*========================================================================================*/

.haf-show-company tbody {
  display:block;
  overflow-y:scroll;
  height:150px;
  width:100%;
  text-align:center;
}
.haf-show-company thead tr {
  display:block;
  text-align:center;
}
.progress-preview {
  /* display:block;
  width:100%;
  margin-top:129px; */
  display: flex;
  align-items: center !important;
  justify-content: center  !important;
}

.progress-cl-viewer {
  width:calc(100vh - 350px);
  /* min-width: 100%; */
}

/* .download-microsoft-word-version {
  display:flex;
  text-align:left;
  margin-left: 10px;
} */

.container-card-viewer {
  display: flex;
  align-items: center !important;
  justify-content: center  !important;

}

.card-viewer {
  /* display:flex;
  justify-content: space-between;
  align-items: center; */
  /* min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px); */
  min-height: 460px;
  max-height: 460px;
  min-width: 90% !important;
  max-width: 90% !important;
}

.tabcontent-card-viewer {
  max-height: 370px;
  min-height: 370px;
}

div.haf-previewnew-viewer {
  min-height: 340px;
  max-height: 340px;
  /* height: 100vh; */
  overflow:auto;
  /* user-select: none; */
}
haf-form-action {
  display: inline-block;
  border:1px solid #777;
  padding: 10px;
}

.haf-newcontract-modal {
  overflow:auto;
  /* min-height: calc(100vh - 300px); */
  min-height: calc(100vh - 80px);
  /* height: calc(100vh); */
  /* min-width: calc(100vw - 450px); */
  min-width: 900px;
}

.header-wrap {white-space:nowrap;}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
	background: #ffe0a6;
}
.checked-adult:checked + label {
	background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
	color: #666;
}

/* Disabled */
.form_radio_group input[type=radio]:disabled + label {
	background: #efefef;
	color: #666;
}

.backgreound-clr {
  background: #f5f5f5;
}

.contract-result-editor {
  height: 120px;
}

/*========================================================================================*/
/*                                                                                      *
 *                                   SMS CHECKBOX                                   *
 *                                                                                      */
/*========================================================================================*/

.haf-sms-yes{
  color: #3FB547;
}

.haf-sms-no{
  color: #EF5350;
}

.header-wrap {white-space:nowrap;}


.treatmentplan-result-editor {
  width: 100%;
  height:100px;
}

/*========================================================================================*/
/*                                                                                      *
 *                                      FILES TABLE                                     *
 *                                                                                      */
/*========================================================================================*/

.haf-files-table {
  /* display:block; */
  /* overflow-y:scroll; */
  /* height:211px; */
  width:100%;
  text-align:center;
}
.haf-files-table thead tr {
  /* display:block; */
  text-align:center;
}
.haf-files-table td {
  text-align:center;
}

.button-wrapper{
	display: flex;
	justify-content: center;
}
.open-ai-card{
  margin-top: 10px;
  border: 2px solid grey;
  overflow-y: scroll;
  overflow-x: scroll;
  /* max-height: 100px; */
  min-height: 100px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  max-height: calc(100vh - 210px);
  -webkit-overflow-scrolling: touch;
  padding-left: 5px;
  padding-top: 5px;

  /* align-items: center;
  justify-content: center; */
}

/* .prevent-select  {
  width: 50vw;
  height: 50vh;
  overflow: scroll;
} */
/* #iframe {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
} */
/* .iframe {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  zoom: 50%;
  } */
/************************************************/
/* @media screen and (max-width: 575px) {
    .haf-selectType-deck div.card  {
       display:inline-block;
       flex: calc(1/3);
       background-color:burlywood;
       align-items: center;
    }
} */


/*GLOBAL OVERIDE*/
/* html,body{
  overflow:hidden;
} */

/* .tooltip-inner {
    white-space: pre-line;
} */
