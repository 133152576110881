    .modal{
        display: block !important; 
        overflow-y: initial !important;
        width: 100%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
        animation-name: animatetop;
    }
    .modalbody{
        height: 50px;    
        overflow-y: auto;
        background-color: #E4E5E7;
    }
    .modalheader{
        background-color: #FABE09;
        color: white;
    }
    
    .modalfooter{
        border: none;
        background-color: #E4E5E7;   
    }